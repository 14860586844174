<!--
wangEditor文档地址:https://www.wangeditor.com/v5/getting-started.html
-->
<template>
	<div>
		<div style="border: 1px solid #ccc; margin-top: 10px">
			<!-- 工具栏 -->
			<div class="toolbar-wrapper">
				<Toolbar :defaultConfig="toolbarConfig" :editor="editor" style="border-bottom: 1px solid #ccc" />
				<div class="pic-btn">
					<el-button type="custom-primary" icon="el-icon-picture-outline">添加图片</el-button>
				</div>
			</div>
			<!-- 编辑器 -->
			<Editor :defaultConfig="editorConfig" @onCreated="onCreated" @onChange="onChange" :style="styles" v-model="html" />
		</div>
	</div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { getToken } from '@/utils/auth.js';

export default {
	name: 'editorPlus',
	components: { Editor, Toolbar },
	// 接收父类的传值
	props: {
		content: {
			type: String,
			default: ''
		},
		dir: {
			type: String,
			default: 'default'
		},
		/* 高度 */
		height: {
			type: Number,
			default: 300
		},
		/* 最小高度 */
		minHeight: {
			type: Number,
			default: null
		},
		/* 只读 */
		readOnly: {
			type: Boolean,
			default: false
		},
		// 上传文件大小限制(MB)
		fileSize: {
			type: Number,
			default: 5
		},
		/* 类型（base64格式、url格式） */
		type: {
			type: String,
			default: 'url'
		}
	},
	//监控传参，及时更新为新值
	watch: {
		content: {
			handler(val) {
				if (val !== this.html) {
					if (val) {
						this.html = val;
					} else {
						//  为啥赋值 null 不行
						this.html = ' ';
					}
				}
			},
			immediate: true
		}
	},
	computed: {
		styles() {
			let style = {};
			if (this.minHeight) {
				style.minHeight = `${this.minHeight}px`;
			}
			if (this.height) {
				style.height = `${this.height}px`;
			}
			return style;
		}
	},
	data() {
		return {
			editor: null,
			html: null,
			toolbarConfig: {
				// toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
				// excludeKeys: ['insertVideo', 'uploadVideo','editVideoSize'],
			},
			editorConfig: {
				placeholder: '请输入内容...',
				autoFocus: false,
				// 所有的菜单配置，都要在 MENU_CONF 属性下 参考链接：https://www.wangeditor.com/v5/menu-config.html#%E4%B8%8A%E4%BC%A0%E5%9B%BE%E7%89%87
				MENU_CONF: {
					uploadImage: {
						// 后端上传地址，必填
						server: process.env.VUE_APP_BASE_API + '/common/upload',
						// form-data fieldName，后端接口参数名称，默认值 wangeditor-uploaded-image
						fieldName: 'file',
						// 10M，单个文件的最大体积限制，默认为 2M
						maxFileSize: 100 * 1024 * 1024,
						// 最多可上传几个文件，默认为 100
						maxNumberOfFiles: 10,
						// 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
						allowedFileTypes: ['image/*'],
						// 15 秒，超时时间，默认为 10 秒
						timeout: 15 * 1000,
						// 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
						meta: {
							dir: this.dir
							// token: '', // 上传凭证
							// key: '', // 自定义文件名称
						},
						// 将 meta 拼接到 url 参数中，默认 false
						metaWithUrl: false,
						// 自定义增加 http  header
						headers: { Authorization: 'Bearer ' + getToken() }
						// 跨域是否传递 cookie ，默认为 false
						// withCredentials: false,
					},
					uploadVideo: {
						// 后端上传地址，必填
						server: process.env.VUE_APP_BASE_API + '/common/upload',
						// form-data fieldName，后端接口参数名称，默认值wangeditor-uploaded-video
						fieldName: 'file',
						// 500M，文件大小限制，默认10M
						maxFileSize: 100 * 1024 * 1024,
						// 最多可上传几个文件，默认为 5
						maxNumberOfFiles: 3,
						// 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
						allowedFileTypes: ['video/*'],
						// 15 秒，超时时间，默认为 30 秒
						timeout: 15 * 1000,
						// 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
						// meta: {
						//     token: 'xxx',
						//     otherKey: 'yyy'
						// },
						// 将 meta 拼接到 url 参数中，默认 false
						// metaWithUrl: false,
						// 自定义增加 http  header
						headers: { Authorization: 'Bearer ' + getToken() }
						// 跨域是否传递 cookie ，默认为 false
						// withCredentials: false,
					}
				}
			}
		};
	},
	mounted() {},
	created() {},
	methods: {
		onChange(editor) {
			this.$emit('changeEditor', editor.getHtml());
			// console.log('onChange', editor.getHtml()) // onChange 时获取编辑器最新内容
		},
		onCreated(editor) {
			this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
			console.log('创建组件', this.editor);
			console.log(130, '默认菜单配置项', editor.getAllMenuKeys());
			// this.insertHtml();
		}
	},
	beforeDestroy() {
		console.log('组件销毁时', this.editor);
		const editor = this.editor;
		if (editor == null) return;
		editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
	}
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>

<style lang="scss">
.w-e-text-placeholder {
	background: url('~@/assets/img/editor.png');
	background-repeat: no-repeat;
	background-size: 16px 16px;
	background-position: left center;
	padding-left: 26px;
}
</style>
<style lang="scss" scoped>
.toolbar-wrapper {
	position: relative;
	.pic-btn {
		position: absolute;
		right: 25px;
		bottom: 10px;
		::v-deep {
			.el-button {
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				box-sizing: border-box;
				width: 92px;
				height: 30px;
				border-radius: 4px 4px 4px 4px;
				font-family: PingFang SC, PingFang SC;
				font-weight: 500;
				font-size: 14px;
			}
		}
	}
}
</style>
