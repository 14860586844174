import Vue from 'vue'
import axios from 'axios'
Vue.prototype.$axios = axios
import config from '@/common/config.js'
import store from '@/store/index.js'
import QS from 'qs'
import md5 from 'js-md5'
import {
	blobValidate
} from "@/utils/utils.js";
import {
	saveAs
} from 'file-saver'


// create an axios instance
const service = axios.create({
	baseURL: `${config.host}`, // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 300000 // request timeout，
})

// request interceptor
service.interceptors.request.use(
	// requestConfig => {
	// 	let TIMESTAMP = new Date().getTime();
	// 	// 生成签名
	// 	let siData = ''
	// 	CalcuMD5(siData).then(sign => {
	// 		requestConfig.headers['Yw-Sign'] = sign
	// 		requestConfig.headers['SIGN'] = sign
	// 		requestConfig.headers['userRole'] = 'manufacturer'
	// 		requestConfig.headers['Yw-Time'] = (Date.parse(new Date())) / 1000
	// 		requestConfig.headers['TIMESTAMP'] = TIMESTAMP
	// 		requestConfig.headers['token'] = localStorage.getItem('token') || ''
	// 	})
	// 	return requestConfig
	// },
	configs => {
		CalcuMD5(QS.parse(configs.data)).then(signMD5 => {
			let authorization = localStorage.getItem('token') || ''
			if (authorization) {
				configs.headers['authorization'] = authorization
			}
		})
		return configs
	},
	error => {
		// do something with request error
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		// 统一定义状态码为200时，表示请求成功，其他请求失败
		console.log('response: ', response);
		if (!response.data.code) {
			return Promise.resolve(response.data)
		}

		if (response.data.code == 200) {
			return Promise.resolve(response.data)
		} else if (response.data.code == 400) {
			Vue.prototype.$notify.error({
				title: response.data.code,
				message: `${response.data.message || '接口错误，请联系管理员'}`,
			})
			return Promise.reject(response.data)
		} else if (response.data.code == 401) {
			Vue.prototype.$notify.error({
				title: response.data.code,
				message: `${response.data.message || '登录已过期，请重新登录'}`,
			})
			Vue.prototype.$bus.$emit('login')
			return Promise.reject(response.data)
		} else if (response.data.code == 404) {
			Vue.prototype.$notify.error({
				title: response.data.code,
				message: `${response.data.message || '接口不存在'}`,
			})
			return Promise.reject(response.data)
		} else if (response.data.code == 500) {
			Vue.prototype.$notify.error({
				title: response.data.code,
				message: `${response.data.message || '服务器内部错误'}`,
			})
			return Promise.reject(response.data)
		} else if (response.data.code == 601) {
			// 没有考试
			return Promise.reject(response.data)
		} else {
			//失败
			Vue.prototype.$notify.error({
				title: response.data.code,
				message: `${response.data.message || '发生错误了，请联系管理员'}`,
			})
			return Promise.reject(response.data);
		}
	},
	error => {
		console.log('error', error);
		if (error.response) {
			const res = error.response.data
			const status = error.response.status
			if (status == 500) {
				Vue.prototype.$notify.error({
					title: status,
					message: `${res.data || '服务未启动，请联系管理员'}`
				})
				return Promise.reject(error)
			} else if (status == 403) {
				if (res.code == 401) {
					Vue.prototype.$notify.error({
						title: res.code,
						message: `${res.message || '登录已过期，请重新登录'}`
					})
					Vue.prototype.$bus.$emit('login')
					return Promise.reject(error)
				} else {
					Vue.prototype.$notify.error({
						title: status,
						message: `${error.code}`
					})
					return Promise.reject(error)
				}

			} else if (status == 404) {
				Vue.prototype.$notify.error({
					title: status,
					message: '系统更新中，请稍后再试'
				})
				return Promise.reject(error)
			} else {
				Vue.prototype.$notify.error({
					title: status,
					message: `${error.code}`
				})
				return Promise.reject(error)
			}
		} else {
			Vue.prototype.$notify.error({
				title: status,
				message: `${error.code}`
			})
			return Promise.reject(error)
		}
	}
)

/**
 * 作者：TKE
 * 时间：2019/12/17 0017
 * 功能：对外接口
 * 描述：
 */

let urls = []

export default function request(options) {
	// 数据处理
	const params = options.data || {}
	const method = options.method.toUpperCase()
	const url = options.url
	const responseType = options.responseType || 'json'
	const ContentType = options.ContentType || 'application/json;charset=UTF-8'
	const preventRepetition = options.preventRepetition || false
	//  'application/x-www-form-urlencoded;application/vnd.ms-excel;charset=UTF-8'

	if (preventRepetition) {
		if (method == 'get') {
			if (Object.keys(params).length) {
				for (let key in params) {
					if (url.indexOf('?') == -1) {
						url = `${url}?${key}=${params[key]}`
					} else {
						url = `${url}&${key}=${params[key]}`
					}
				}
				params = {}
			}
		}
		let index = urls.indexOf(url)
		if (index > -1) {
			return new Promise((resolve, reject) => {
				console.log(`重复请求已被拦截:${url}`);
				reject()
			})
		} else {
			urls.push(url)
		}
	}
	return new Promise((resolve, reject) => {
		begincommin({
			url,
			method,
			params,
			responseType,
			ContentType
		}).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err);
		}).finally(() => {
			if (preventRepetition) {
				setTimeout(() => {
					let index = urls.indexOf(url)
					if (index > -1) {
						urls.splice(index, 1)
					}
				}, 200)
			}
		});
	})

}

const begincommin = ({
	url,
	method,
	params,
	responseType,
	ContentType
}) => {
	return new Promise((resolve, reject) => {
		if (method === 'GET') {
			service({
				url,
				method,
				params,
				responseType
			}).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		} else if (method === 'POST') {
			// axios.defaults.headers.post['Content-Type'] = ContentType
			const data = params //QS.stringify(params)
			service({
				url,
				method,
				data,
				responseType,
				headers: {
					"Content-Type": ContentType
				}
			}).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		} else if (method === 'PUT' || method === 'PATCH') {
			// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

			const data = QS.stringify(params)
			service({
				url,
				method,
				data,
				responseType
			}).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		} else if (method === 'DELETE') {
			service({
				url,
				method,
				params,
				responseType
			}).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		}
	})
}

/**
 * 作者： TKE
 * 时间： 2019/12/17 0017
 * 功能：md5加密请求体
 * 描述：
 */
function CalcuMD5(obj) {
	return new Promise(resolve => {
		// 如果不是对象  也就是说使用的GET和DELETE请求 那么不用加密  直接返回空
		const key = process.env.VUE_APP_AESKEY
		let sign = ''
		if (Object.prototype.toString.call(obj) !== '[object Object]') {
			sign = key + '--' + key
		} else {
			if (JSON.stringify(obj) === '{}') {
				sign = key + '--' + key
			} else {
				Object.keys(obj).sort().forEach((k) => {
					sign += key + k + '--' + obj[k] + key
				})
			}
		}
		resolve(md5(sign).toUpperCase())
	})
}

// 通用下载方法
export function download(url, filename) {
	let downloadLoadingInstance = Vue.prototype.$loading({
		text: "正在下载数据，请稍候",
		spinner: "el-icon-loading",
		background: "rgba(0, 0, 0, 0.7)",
	})
	return service.get(url, {
		// headers: {
		// 	'Content-Type': 'application/json;charset=UTF-8'
		// },
		responseType: 'blob'
	}, ).then(async (data) => {
		let flag = await blobValidate(data)
		console.log('flag: ', flag);
		if (flag) {
			const blob = new Blob([data])
			saveAs(blob, filename)
			downloadLoadingInstance.close();
		} else {
			const text = await data.text();
			let res = JSON.parse(text);
			Vue.prototype.$notify.error({
				title: `${res.code}`,
				message: `${res.msg}`,
			})
			downloadLoadingInstance.close();
		}
	}).catch((r) => {
		console.error(r)
		downloadLoadingInstance.close();
	})
}