<template>
	<div id="app" class="app-component" :class="[`app-${$route.name}`, { 'app-headBorder': scrollHeight > 0 }]">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			scrollHeight: 0
		};
	},
	created() {
		// 动态设置浏览器图标和标题
		// this.setWebsite();
		this.checkTokenInterval();
		window.addEventListener('scroll', this.handleScroll);
		this.$bus.$on('login', this.handleLogin);
	},
	mounted() {
		let appEl = document.getElementById('app');
		this.$bus.$on('backTop', (data = {}) => {
			appEl.scrollIntoView(data);
		});
	},
	methods: {
		checkTokenInterval() {
			setInterval(() => {
				let authorization = localStorage.getItem('token') || '';
				if (this.$route.name !== 'login' && this.$route.name !== 'not-found' && this.$route.name !== 'agreement') {
					if (!authorization) {
						this.$bus.$emit('login');
					}
				}
			}, 1000);
		},
		setWebsite() {
			document.title = '自定义标题';
			var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
			link.type = 'image/x-icon';
			link.rel = 'shortcut icon';
			link.href = 'https://www.ecdigit.com/favicon.ico';
			document.getElementsByTagName('head')[0].appendChild(link);
		},
		handleLogin() {
			this.$store.dispatch('updateToken');
			this.replaceUrl({
				name: 'login'
			});
		},
		handleScroll() {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			this.scrollHeight = scrollTop;
			// 在这里可以根据scrollTop的值进行相应的操作
		}
	}
};
</script>

<style lang="scss">
@import '@/common/common.scss';
@import '@/common/element-ui.scss';
@import '@/common/quill.scss';
// @import '/src/common/iconfont.scss';
// @import '/src/common/animation.scss';

body {
	// background-color: $bg-gray;
}
#app {
	min-width: 1280px;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// box-sizing: border-box;
	// text-align: center;
	// color: #2c3e50;
	/* margin-top: 60px; */
}

// 设置页面背景色
.app-component {
	background-color: $page-bg-gray;
}
.app-home,
.app-scored {
	background-color: $page-bg-dark;
}
</style>
