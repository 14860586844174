<template>
	<div class="main-component">
		<keep-alive :include="[]">
			<router-view />
		</keep-alive>
	</div>
</template>

<script>
export default {
	name: 'Main',
	data() {
		return {};
	}
};
</script>

<style lang="scss" scoped>
.main-component {
}
</style>
