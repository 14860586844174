import Vue from 'vue'
import Vuex from 'vuex'
import {
	ExamsPaper
} from '@/common/api.js'
Vue.use(Vuex)

// 用户信息
let token = ''

export default new Vuex.Store({
	state: {
		token: localStorage.getItem("token") || '', //用户校验
		userInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {}, //用户信息
		examsPaperAnswerCache: sessionStorage.getItem("examsPaperAnswerCache") ? JSON.parse(sessionStorage
			.getItem("examsPaperAnswerCache")) : [], //用户信息
		examsPaperStatus: sessionStorage.getItem("examsPaperStatus") || '' // 试卷状态
	},
	getters: {
		isLogin: state => {
			if (!state.token) {
				return false
			}
			return true;
		},
	},
	mutations: {
		changeToken(state, data = '') {
			try {
				localStorage.setItem("token", data)
			} catch (error) {}
			state.token = data
			console.log("changeToken: ", data);
		},
		changeUserInfo(state, data = {}) {
			try {
				localStorage.setItem("userInfo", JSON.stringify(data))
			} catch (error) {}
			state.userInfo = data
			console.log("changeUserInfo: ", data);
		},
		changeExamsPaperAnswerCache(state, data = []) {
			try {
				sessionStorage.setItem("examsPaperAnswerCache", JSON.stringify(data))
			} catch (error) {}
			state.examsPaperAnswerCache = data
			console.log("changeExamsPaperAnswerCache: ", data);
		},
		changeExamsPaperStatus(state, data = {}) {
			try {
				sessionStorage.setItem("examsPaperStatus", JSON.stringify(data))
			} catch (error) {}
			state.examsPaperStatus = data
			console.log("changeExamsPaperStatus: ", data);
		},

	},
	actions: {
		// 更新用户token
		updateToken({
			dispatch,
			commit
		}, data = '') {
			if (!data) {
				return new Promise(async (resolve, reject) => {
					commit('changeToken')
					commit('changeUserInfo')
					commit('changeExamsPaperAnswerCache')
					commit('changeExamsPaperStatus')
					resolve()
				})
			} else {
				return new Promise(async (resolve, reject) => {
					commit('changeToken', data)
					// await dispatch('updateUserInfo')
					resolve()
				})
			}

		},
		// 更新用户信息
		updateUserInfo({
			dispatch,
			commit,
			state,
			getters
		}) {
			return new Promise((resolve, reject) => {
				UserGetUserInfo().then(
					async res => {
							commit('changeUserInfo', res.data)
							await dispatch('updateUserPermissions')
							resolve(res.data)
						},
						err => {
							reject(err)
						}
				)
			})

		},
		// 更新试卷状态
		updateExamsPaperStatus({
			dispatch,
			commit,
			state,
			getters
		}) {
			return new Promise((resolve, reject) => {
				ExamsPaper().then(
					async res => {
							let finishedFlag = res.data.paperRecord.finishedFlag // 0 未完成 1 已完成
							let inspectionFlag = res.data.paperRecord
								.inspectionFlag // 0 未批阅 1 已批阅 2 已发布
							let nowTime = new Date();
							let startTime = new Date(res.data.startTime)
							let endTime = new Date(res.data.endTime)
							let timeFlag = '' // 0 考试未开始 1 考试进行中 2 考试已结束
							if (nowTime < startTime) {
								timeFlag = 0
							} else if (nowTime > startTime && nowTime < endTime) {
								timeFlag = 1
							} else {
								timeFlag = 2
							}
							let status = {
								finishedFlag: finishedFlag,
								inspectionFlag: inspectionFlag,
								timeFlag: timeFlag,
								noPaper: 0
							}
							commit('changeExamsPaperStatus', status)
							resolve(res)
						},
						err => {
							if (err.code == 601) {
								let status = {
									finishedFlag: -1,
									inspectionFlag: -1,
									timeFlag: -1,
									noPaper: 1
								}
								commit('changeExamsPaperStatus', status)
								resolve(err)
							} else {
								let status = {
									finishedFlag: -1,
									inspectionFlag: -1,
									timeFlag: -1,
									noPaper: -1
								}
								commit('changeExamsPaperStatus', status)
								reject(err)
							}
						}
				)
			})

		},

	},
	modules: {}
})