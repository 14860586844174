<template>
	<div class="layout-component">
		<Head class="head-wrapper"></Head>
		<Main class="main-wrapper"></Main>
		<!-- <Foot class="foot-wrapper"></Foot> -->
		<!-- <Tool class="tool-wrapper"></Tool> -->
	</div>
</template>

<script>
import Head from '@/layout/components/Head/Head.vue';
import Main from '@/layout/components/Main/Main.vue';
import Foot from '@/layout/components/Foot/Foot.vue';
import Tool from '@/layout/components/Tool/Tool.vue';
export default {
	name: 'Layout',
	components: {
		Head,
		Main,
		Foot,
		Tool
	},
	data() {
		return {};
	}
};
</script>

<style lang="scss" scoped>
.layout-component {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	.head-wrapper {
	}
	.main-wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.foot-wrapper {
		margin-top: auto;
	}
	.tool-wrapper {
		display: none;
	}
}
</style>
