import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

// 提示弹窗
import TipPlugin from '@/components/Tip/Tip.plugin';
Vue.use(TipPlugin);

// 路由
import router from '@/router/index.js'

// vuex
import store from '@/store/index.js'

// 全局混入
import mixins from '@/mixins/index.js'
Vue.mixin(mixins)

// svg
import '@/common/svg.js'

// 全局组件
import EditorPlus from '@/components/EditorPlus'
import Editor from '@/components/Editor'
Vue.component('EditorPlus', EditorPlus)
Vue.component('Editor', Editor)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this //安装全局事件总线
	}
}).$mount('#app')