import request from '@/common/request.js'
import {
	download
} from '@/common/request.js'
import moment from 'moment';

// method 请求方式
// url 接口地址
// data 请求参数
// preventRepetition 是否防止重复请求 默认值 false

//试卷
export function ExamsPaper(data = {}) {
	return request({
		method: `get`,
		url: `/front/app/examsPaper/getPaper`
	})
}

//试卷状态（已弃用）
export function ExamsPaperStatus(data = {}) {
	return request({
		method: `get`,
		url: `/front/app/examsPaper/status`
	})
}

//协议
export function AgreementInfo(data = {}) {
	let {
		type = ''
	} = data
	return request({
		method: `get`,
		url: `/feign/info/agreement/${type}`
	})
}

//get
export function get(data = {}) {
	return request({
		method: `get`,
		url: ``
	})
}








//post
export function post(data = {}) {
	return request({
		method: `post`,
		url: ``,
		data
	})
}

//提交
export function ExamsPaperSubmit(data = {}) {
	return request({
		method: `post`,
		url: `/front/app/examsPaper/submit`,
		data
	})
}

//保存
export function ExamsPaperSave(data = {}) {
	return request({
		method: `post`,
		url: `/front/app/examsPaper/save`,
		data
	})
}

//登录
export function Login(data = {}) {
	let {
		username = '',
			password = ''
	} = data
	return request({
		method: `post`,
		url: `/feign/user/login?username=${username}&password=${password}`,
		data
	})
}