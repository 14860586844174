<template>
	<div class="tool-component">
		<div class="tools">
			<div class="tool-item top" @click="scrollToTop()">
				<svg-icon icon-class="zd" />
			</div>
		</div>
		<RetrievalsCode ref="RetrievalsCode"></RetrievalsCode>
	</div>
</template>

<script>
export default {
	name: 'Tool',
	data() {
		return {};
	},
	methods: {
		scrollToTop() {
			this.$bus.$emit('backTop', { behavior: 'smooth' });
		}
	}
};
</script>

<style lang="scss" scoped>
.app-semantic .tool-item {
	&.retrievals {
		display: flex !important;
	}
	&.headphone {
		display: flex !important;
	}
	&.top {
		display: flex !important;
	}
}
.app-advanced .tool-item {
	&.sort {
		display: flex !important;
	}
	&.search {
		display: flex !important;
	}
	&.highlight {
		display: flex !important;
	}
	&.station {
		display: flex !important;
	}
	&.retrievals {
		display: flex !important;
	}
	&.headphone {
		display: flex !important;
	}
	&.top {
		display: flex !important;
	}
}
.app-list .tool-item {
	&.compare {
		display: flex !important;
	}
	&.ipc {
		display: flex !important;
	}
	&.sort {
		display: flex !important;
	}
	&.search {
		display: flex !important;
	}
	&.highlight {
		display: flex !important;
	}
	&.station {
		display: flex !important;
	}
	&.headphone {
		display: flex !important;
	}
	&.top {
		display: flex !important;
	}
}
.tool-component {
	.tools {
		position: fixed;
		// right: 10px;
		left: calc(100vw - 38px - 30px);
		bottom: 30vh;
		display: flex;
		flex-direction: column;
		background: rgba(0, 0, 0, 0.6);
		.tool-item {
			width: 38px;
			height: 48px;
			// display: flex;
			display: none;
			justify-content: center;
			align-items: center;
			position: relative;
			cursor: pointer;
			margin-bottom: 2px;
			&:last-child {
				margin-bottom: 0;
			}
			&:hover {
				.des {
					display: block;
				}
			}
			.des {
				display: none;
				position: absolute;
				top: 0;
				right: 0;
				height: 48px;
				width: 140px;
				background: rgba(0, 0, 0, 0.6);
				box-sizing: border-box;
				padding-left: 12px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: #ffffff;
				line-height: 48px;
			}
			.svg-icon {
				position: absolute;
			}
			.svg-ipc {
				font-size: 12px;
			}
		}
	}
}
</style>
