<template>
	<div class="head-component">
		<div class="head-container" id="head" :class="{ border: border }">
			<div class="head-body M">
				<div class="system-name" @click="toHome()">钢研碳云平台“双碳”技能竞赛系统</div>
				<div class="body-right">
					<!-- <svg-icon name="clock"></svg-icon>
					<svg-icon name="question"></svg-icon> -->
					<template v-if="isLogin">
						<el-dropdown trigger="click" @command="handleCommand">
							<div class="user">
								<div class="avatar">
									<img src="@/assets/img/avatar.png" alt="" />
								</div>
								<div class="user-name">参赛选手-{{ userInfo.name }}</div>
								<svg-icon name="arrowdown"></svg-icon>
							</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="home">首页</el-dropdown-item>
								<el-dropdown-item command="exit">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
					<template v-else>
						<div class="login" @click="toLogin()">未登录，点击登录</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Head',
	props: {
		border: Boolean
	},
	data() {
		return {};
	},
	watch: {
		$route: {
			handler(val, oldval) {},
			// 深度观察监听
			deep: true
		}
	},
	mounted() {},
	methods: {
		toLogin() {
			this.$bus.$emit('login');
		},
		toHome() {
			this.openUrl({
				name: 'home'
			});
		},
		handleCommand(command) {
			if (command == 'exit') {
				this.$bus.$emit('login');
			} else if (command == 'home') {
				this.openUrl({
					name: 'home'
				});
			} else {
				this.$notify('这是一条通知信息');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.app-headBorder .head-container {
	outline: 1px solid #f5f5f5;
}
.app-home {
	.head-component {
		.head-container {
			background: #313f60;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
			.head-body {
				.system-name {
					color: #ffffff;
				}
			}
			
		}
	}
}
.head-component {
	height: $head-height;
	.head-container {
		width: 100%;
		height: $head-height;
		position: fixed;
		z-index: 999;
		top: 0;
		display: flex;
		background: #ffffff;
		.head-body {
			display: flex;
			.system-name {
				font-family: PingFang SC, PingFang SC;
				font-weight: bold;
				font-size: 22px;
				color: #019a7f;
				line-height: 22px;
				align-self: center;
				cursor: pointer;
			}
			.body-right {
				margin-left: auto;
				display: flex;
				.svg-clock {
					font-size: 20px;
					margin-right: 20px;
					align-self: center;
				}
				.svg-question {
					font-size: 20px;
					margin-right: 20px;
					align-self: center;
				}
				.login {
					font-family: PingFang SC, PingFang SC;
					font-weight: bold;
					font-size: 16px;
					color: #999999;
					line-height: 16px;
					align-self: center;
					cursor: pointer;
				}
				.user {
					display: flex;
					align-items: center;
					height: $head-height;
					cursor: pointer;
					.avatar {
						width: 48px;
						height: 48px;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.user-name {
						margin-left: 10px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
						color: #999999;
						line-height: 16px;
					}
					.svg-arrowdown {
						margin-left: 10px;
					}
				}
			}
		}
	}
}
</style>
