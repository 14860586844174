<template>
	<div class="foot-component">
		<div class="foot-body-container">
			<div class="foot-body M">
				<div class="body-left">
					<div class="nav-list">
						<div class="nav-title">关于</div>
						<div class="nav-item">关于我们</div>
						<div class="nav-item">使命宗旨</div>
						<div class="nav-item">加入我们</div>
						<div class="nav-item">联系我们</div>
					</div>
					<div class="nav-list">
						<div class="nav-title">产品</div>
						<div class="nav-item">最新中国发明专利</div>
						<div class="nav-item">最新美国发明专利</div>
					</div>
				</div>
				<div class="body-right">
					<div class="fields">
						<div class="filed">客服（投诉）电话: 18531000091 (9:00 - 18:00)</div>
						<div class="filed">工作日： 08:00 - 22:00</div>
						<div class="filed">官方微信：29392922222</div>
						<div class="filed">官方邮箱：wueudjd@qq.cm</div>
					</div>
				</div>
			</div>
		</div>
		<div class="foot-footer-container">
			<div class="foot-footer M">
				<div class="copyright">
					<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">Copyright © 2021 zhipin.com 备案序号:京ICP备14013441号-5</a>
					<!-- <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202010521" style="margin-left: 10px">京公网安备11010202010521号</a> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Foot',
	data() {
		return {};
	}
};
</script>

<style lang="scss" scoped>
.foot-component {
	display: flex;
	flex-direction: column;
	height: $foot-height;
	.foot-body-container {
		flex: 1;
		background-color: #fff;
		display: flex;
		// justify-content: center;
		.foot-body {
			display: flex;
			justify-content: space-between;
			.body-left {
				display: flex;
				.nav-list {
					display: flex;
					flex-direction: column;
					width: 150px;
					box-sizing: border-box;
					padding-left: 17px;
					.nav-title {
						margin-top: 17px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 600;
						font-size: 14px;
						color: #0a0a0a;
						line-height: 20px;
					}
					.nav-item {
						margin-top: 17px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 12px;
						color: #545557;
						line-height: 20px;
						cursor: pointer;
						&:hover {
							color: #0a0a0a;
						}
					}
				}
			}
			.body-right {
				.fields {
					display: flex;
					flex-direction: column;
					margin-top: calc(44px - 12px);
					.filed {
						margin-top: 12px;
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 10px;
						color: #a6a6a6;
						line-height: 14px;
						text-align: right;
					}
				}
			}
		}
	}
	.foot-footer-container {
		height: 50px;
		display: flex;
		// justify-content: center;
		border-top: 1px solid #ededed;
		background-color: #fff;
		.foot-footer {
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			.copyright {
				a {
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					font-size: 12px;
					color: #999a9c;
					line-height: 20px;
					cursor: pointer;
					&:hover {
						color: #0a0a0a;
					}
				}
			}
		}
	}
}
</style>
