<template>
  <div class="tip-component">
    <el-dialog :visible.sync="dialogVisible" :show-close="false" @closed="onClosed">
      <div class="ipc-dialog">
        <div class="dialog-header">
          <div class="title">提示</div>
          <svg-icon icon-class="shanc" @click="close" />
        </div>
        <div class="dialog-body">
          {{options.content}}
        </div>
        <div class="dialog-footer">
          <el-button type="custom-info" class="btn-close" plain @click="close">取消</el-button>
          <el-button type="custom-primary" class="btn-confirm" @click="confirm">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: ['options'],
    mounted() {
    },
    data() {
      return {
        dialogVisible: true
      };
    },
    watch: {
      dialogVisible: {
        handler: function (val) {
          this.$emit('update:show', val);
        }
      }
    },
    methods: {
      open(obj = {}) {
        this.dialogVisible = true;
      },
      close(obj = {}) {
        this.dialogVisible = false;
      },
      onClosed() {
        // this.$el.removeEventListener('transitionend', this.destroyElement);
        this.$destroy(true);
        this.$el.parentNode.removeChild(this.$el);
      },
      confirm() {
        if (this.options.confirm){
          this.options.confirm()
        }
        this.close()
      },
    }
  };
</script>

<style lang="scss" scoped>
  // 修改自带样式
  ::v-deep .el-dialog__wrapper {
    display: flex;
    align-items: flex-start;
    .el-dialog {
      width: auto;
      background-color: transparent;
      .el-dialog__header {
        padding: 0;
      }
      .el-dialog__body {
        padding: 0;
      }
      .el-dialog__footer {
        padding: 0;
      }
    }
  }
  .tip-component {
    .ipc-dialog {
      width: 420px;
      // height: 420px;
      background: #ffffff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 0 14px 0 17px;
      position: relative;
      .dialog-header {
        padding: 23px 0 18px;
        .title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #000000;
          line-height: 25px;
        }
        .svg-shanc {
          position: absolute;
          top: 20px;
          right: 20px;
          stroke: #000000;
          cursor: pointer;
          &:hover {
            stroke: #1d67e8;
          }
        }
      }
      .dialog-body {
      }
      .dialog-footer {
        display: flex;
        margin-top: 65px;
        margin-bottom: 22px;
        .btn-close {
          margin-left: auto;
        }
        .btn-confirm {
          margin-left: 10px;
          margin-right: 23px;
        }
        .el-button {
          width: 72px;
          height: 30px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }
</style>
