import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import store from '@/store/index.js';

import Layout from '@/layout/Layout.vue';
export const constantRoutes = [
  {
    path: '',
    component: Layout,
    children: [
      {
        //首页
        path: '/',
        name: 'home',
        component: () => import('@/views/home/home'),
        meta: {},
      },
      {
        //答题
        path: '/answer',
        name: 'answer',
        component: () => import('@/views/questions/answer/answer'),
        meta: {},
      },
      {
        //分析题目
        path: '/analysis',
        name: 'analysis',
        component: () => import('@/views/questions/analysis/analysis'),
        meta: {},
      },
    ],
  },
  {
    //登录页
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
  },
  {
    //协议页
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/agreement/agreement'),
  },
  {
    //未匹配页面
    path: '/404',
    name: 'not-found',
    component: () => import('@/views/error/404'),
  },
];

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
  // {
  // 	path: '/system/user-auth',
  // 	component: Layout,
  // 	hidden: true,
  // 	permissions: ['system:user:edit'],
  // 	children: [{
  // 		path: 'role/:userId(\\d+)',
  // 		component: () => import('@/views/system/user/authRole'),
  // 		name: 'AuthRole',
  // 		meta: {
  // 			title: '分配角色',
  // 			activeMenu: '/system/user'
  // 		}
  // 	}]
  // },
  // {
  // 	path: '/system/role-auth',
  // 	component: Layout,
  // 	hidden: true,
  // 	permissions: ['system:role:edit'],
  // 	children: [{
  // 		path: 'user/:roleId(\\d+)',
  // 		component: () => import('@/views/system/role/authUser'),
  // 		name: 'AuthUser',
  // 		meta: {
  // 			title: '分配用户',
  // 			activeMenu: '/system/role'
  // 		}
  // 	}]
  // },
  // {
  // 	path: '/system/dict-data',
  // 	component: Layout,
  // 	hidden: true,
  // 	permissions: ['system:dict:list'],
  // 	children: [{
  // 		path: 'index/:dictId(\\d+)',
  // 		component: () => import('@/views/system/dict/data'),
  // 		name: 'Data',
  // 		meta: {
  // 			title: '字典数据',
  // 			activeMenu: '/system/dict'
  // 		}
  // 	}]
  // },
  // {
  // 	path: '/monitor/job-log',
  // 	component: Layout,
  // 	hidden: true,
  // 	permissions: ['monitor:job:list'],
  // 	children: [{
  // 		path: 'index',
  // 		component: () => import('@/views/monitor/job/log'),
  // 		name: 'JobLog',
  // 		meta: {
  // 			title: '调度日志',
  // 			activeMenu: '/monitor/job'
  // 		}
  // 	}]
  // },
  // {
  // 	path: '/tool/gen-edit',
  // 	component: Layout,
  // 	hidden: true,
  // 	permissions: ['tool:gen:edit'],
  // 	children: [{
  // 		path: 'index/:tableId(\\d+)',
  // 		component: () => import('@/views/tool/gen/editTable'),
  // 		name: 'GenEdit',
  // 		meta: {
  // 			title: '修改生成配置',
  // 			activeMenu: '/tool/gen'
  // 		}
  // 	}]
  // },
];

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  mode: 'hash',
  base: '/examspaper/',
  // mode: 'history', // 去掉url中的#
  // scrollBehavior: () => ({y: 0}),
  routes: constantRoutes,
});

// 默认跳转至首页
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next({
      name: 'not-found',
    });
  } else {
    if (!store.state.token) {
      if (
        to.name !== 'login' &&
        to.name !== 'not-found' &&
        to.name !== 'agreement'
      ) {
        next({
          name: 'login',
        });
      } else {
        next();
      }
    } else {
      if (to.name == 'login') {
        next({
          name: 'home',
        });
      } else {
        next();
      }
    }
  }
});

export default router;
