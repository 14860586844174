// notification.plugin.js
import Tip from './Tip.vue';

const TipPlugin = {
  install(Vue) {
    // 注册全局方法
    Vue.prototype.$tip = (options = {}) => {
      // 创建一个临时的通知组件实例
      const Constructor = Vue.extend(Tip);
      const instance = new Constructor({
        propsData: { options }
      });

      // 将实例挂载到body上
      instance.$mount();
      document.body.appendChild(instance.$el);
    };
  }
};

export default TipPlugin;